<template>
  <PersonBase
    :person="person"
    :view="view"
    :shortname="shortname"
    :fullname="fullname"
    :paddingless="paddingless"
    :clickable="clickable"
    :selectable="selectable"
    :checked="checked"
    :no-style="noStyle"
    :custom-class="customClass"
    :rejected="rejected"
    :is-active="isActive"
    :open-details-on-click="openDetailsOnClick"
    @clicked="processClick"
  >
    <template v-slot:leading>
      <slot name="leading"></slot>
    </template>
    <template v-slot:secondline>
      <slot name="secondline"></slot>
    </template>
    <template v-slot:options>
      <slot name="options"></slot>
    </template>
    <template v-slot:modals>
      <b-modal
        :active="activeModal !== null"
        scroll="keep"
        :can-cancel="true"
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :width="700"
        :on-cancel="closeModal"
        :custom-class="'rounded-buefy-modal'"
      >
        <UserDetails :user="person" @updated-user="person = $event" @close.stop="closeModal" />
      </b-modal>
    </template>
  </PersonBase>
</template>

<script>
import UserDetails from '@/components/modals/UserDetails'
import PersonBase from '@/fw-modules/fw-core-vue/ui/components/cards/PersonBase'

export default {
  name: 'PersonCard',

  components: {
    UserDetails,
    PersonBase,
  },

  props: {
    noStyle: {
      type: Boolean,
      default: false,
    },
    view: {
      type: String,
      default: 'row',
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    openDetailsOnClick: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    paddingless: {
      type: Boolean,
      default: false,
    },
    fullname: {
      type: Boolean,
      default: false,
    },
    shortname: {
      type: Boolean,
      default: false,
    },
    person: {
      type: Object,
      default() {
        return {
          full_name: 'Name',
          number: '12345',
        }
      },
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    rejected: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: '',
    },
    overrideUserClick: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentValue: false,
      activeModal: null,
    }
  },

  computed: {
    hasSecondLineSlot() {
      return !!this.$slots['secondline']
    },
    hasOptionsSlot() {
      return !!this.$slots['options']
    },
    showShortAndFullname() {
      return (
        this.shortname &&
        this.fullname &&
        this.person.display_name &&
        this.person.display_name !== this.person.full_name
      )
    },
  },

  created() {
    this.currentValue = this.checked
  },

  methods: {
    openModal(type) {
      this.activeModal = type
    },
    closeModal() {
      this.activeModal = null
    },
    selectPerson() {
      if (this.selectable) {
        this.$emit('selected', this.currentValue)
      }
    },
    getPersonName() {
      return !this.fullname ? this.person.display_name || this.person.full_name : this.person.full_name
    },
    processClick() {
      console.log('processClicPerson')
      if (this.overrideUserClick) {
        this.$emit('clicked', true)
        return
      }
      if (!this.selectable && this.clickable) {
        if (this.openDetailsOnClick) {
          this.openModal('user-details')
        } else {
          this.$emit('clicked', true)
        }
      }
    },
  },
}
</script>

<style>
.fw-row-checkbox-selectable input[type='checkbox']:checked + .fw-row-checkbox-inside {
  background-color: rgba(45, 212, 191, 0.1);
}

.fw-row-checkbox-selectable:hover input[type='checkbox']:checked + .fw-row-checkbox-inside {
  background-color: rgba(45, 212, 191, 0.5);
}
.fw-row-checkbox-selectable input[type='checkbox'] + .fw-row-checkbox-inside .check {
  position: absolute;
  background: white;
  border: solid 1px #6accb3;
}
.fw-row-checkbox-selectable input[type='checkbox']:checked + .fw-row-checkbox-inside .check {
  background: #30a898
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath style='fill:%23fff' d='M 0.04038059,0.6267767 0.14644661,0.52071068 0.42928932,0.80355339 0.3232233,0.90961941 z M 0.21715729,0.80355339 0.85355339,0.16715729 0.95961941,0.2732233 0.3232233,0.90961941 z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat center center;
  border-color: #30a898;
  background-size: 80%;
}

.fw-row-checkbox-selectable input[type='checkbox']:active + .fw-row-checkbox-inside .check {
  border-color: #30a898;
}

.fw-row-checkbox-selectable input[type='checkbox']:focus + .fw-row-checkbox-inside .check {
  border-color: #30a898;
}

.fw-row-checkbox-selectable input[type='checkbox']:active + .fw-row-checkbox-inside {
  outline-style: solid;
  outline-width: 0px;
  outline-color: rgba(45, 212, 191, 0.5);
}

.fw-row-checkbox-selectable input[type='checkbox']:focus + .fw-row-checkbox-inside {
  outline-style: solid;
  outline-width: 0px;
  outline-color: rgba(45, 212, 191, 0.5);
}

.fw-row-checkbox-selectable input[type='checkbox'] + .fw-row-checkbox-inside .repo-icon {
  background-color: rgba(229, 231, 235, 0.5);
}
.fw-row-checkbox-selectable input[type='checkbox']:checked + .fw-row-checkbox-inside .repo-icon {
  background-color: white;
}
.fw-row-checkbox-selectable input[type='checkbox'] + .fw-row-checkbox-inside .id-label {
  background-color: rgb(234, 234, 234);
  color: rgb(59, 58, 58);
}
.fw-row-checkbox-selectable input[type='checkbox']:checked + .fw-row-checkbox-inside .id-label {
  background-color: #30a898;
  color: white;
}

.fw-row-checkbox .fw-row-checkbox-inside .repo-icon {
  background-color: rgba(230, 232, 234, 0.5);
}
.fw-row-checkbox:hover .fw-row-checkbox-inside .repo-icon {
  background-color: #def8ef;
}

.fw-row-checkbox .fw-row-checkbox-inside .id-label {
  background-color: rgb(234, 234, 234);
  color: rgb(59, 58, 58);
}
.fw-row-checkbox .fw-row-checkbox-inside .id-label {
  background-color: #30a898;
  color: white;
}
</style>
