<template>
  <BlockUserDetails :user="user" :loading="loading">
    <PanelPersonalInformationForm
      :team-key="teamKey"
      :user-key="user.key"
      :league-key="leagueKey"
      :boxed="false"
      :management="true"
      :tournament="tournament"
      :editable="false"
    ></PanelPersonalInformationForm>
  </BlockUserDetails>
</template>

<script>
import PanelPersonalInformationForm from '@/components/panels/PanelPersonalInformationForm'
import BlockUserDetails from '@/fw-modules/fw-core-vue/id/components/blocks/BlockUserDetails'

export default {
  name: 'UserDetails',
  components: { BlockUserDetails, PanelPersonalInformationForm },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    savingData: {
      type: Boolean,
      default: false,
    },
    teamKey: {
      type: String,
      required: true,
    },
    leagueKey: {
      type: String,
    },
    tournament: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: true,
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    loggedUser() {
      return this.$store.getters.getUser
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "queues": "Filas",
    "noQueues": "Sem filas atribuídas.",
    "role": {
      "space-admin": "Gestor de espaço",
      "queue-admin": "Gestor da(s) fila(s)",
      "queue-worker": "Trabalhador da(s) fila(s)"
    },
    "chooseAtLeastOneQueue": "Escolha pelo menos uma fila"
  },
  "en": {
    "queues": "Queues",
    "noQueues": "No queues assigned.",
    "role": {
      "space-admin": "Space manager",
      "queue-admin": "Queue manager",
      "queue-worker": "Queue worker"
    },
    "chooseAtLeastOneQueue": "Choose at least one queue"
  }
}
</i18n>
